import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const OAuthCallback = () => {
   const location = useLocation();
  const navigate = useNavigate();
  const url = 'https://api.1cybernet.com/leads/callback'

  useEffect(() => {

    const fetchOAuthToken = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');

      console.log('fetchOAuthToken')

      if (code && state) {
        try {
          const response = await axios.get(url, {
            params: { code, state }
          });

          if (response.data.status === 'success') {
            // Redirect to the desired page after successful OAuth
            navigate('/');
          } else {
            // Handle error, maybe show a message to the user
            console.error('OAuth error', response.data);
          }
        } catch (error) {
          console.error('Error fetching OAuth token', error);
        }
      }
    };

    fetchOAuthToken();

  }, [location, navigate]);

  return (
    <div>
      <p>Processing OAuth callback...</p>
    </div>
  );
};

export default OAuthCallback;