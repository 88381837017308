import case1 from "./image/case1.png";
import case2 from "./image/case2.png";
import case5 from "./image/case5.png";
import case6 from "./image/case6.png";
import case7 from "./image/case7.png";
import case9 from "./image/case9.png";
import case10 from "./image/case10.png";
import case11 from "./image/case11.png";
import case12 from "./image/case12.png";
import case3 from "./image/case3.png";
import case4 from "./image/case4.png";
import case8 from "./image/case8.png";
export const prodArr = [
  {
    id: 1,
    title: "",
    desc: "HVAC company in Los Angeles",
    year: "2023",
    img: case1,
  },
  {
    id: 2,
    title: "",
    desc: "Automation of productions systems",
    year: "2017",
    img: case5,
  },
  {
    id: 3,
    title: "",
    desc: "Wholesale trading company",
    year: "2022",
    img: case8,
  },
  {
    id: 4,
    title: "",
    desc: "HVAC company in Las Vegas",
    year: "2022",
    img: case2,
  },
  {
    id: 5,
    title: "",
    desc: "Buisiness automation in Los Angeles",
    year: "2019",
    img: case6,
  },
  {
    id: 6,
    title: "",
    desc: "SRM system in Los Angeles",
    year: "2013",
    img: case7,
  },
];
export const prodArr2 = [
  {
    id: 7,
    title: "",
    desc: "Luxury clothing Design in Los Angeles",
    year: "2018",
    img: case10,
  },
  {
    id: 8,
    title: "",
    desc: "Online schooll in Los Angeles",
    year: "2020",
    img: case11,
  },
  {
    id: 9,
    title: "",
    desc: "Review site",
    year: "2020",
    img: case12,
  },
  {
    id: 10,
    title: "",
    desc: "Enginieering company in Las Vegas",
    year: "2020",
    img: case9,
  },
  ,{
    id:11,
    title: "",
    desc: "AC company in Las Vegas",
    year: "2016",
    img: case3,
  },
  {
    id: 12,
    title: "",
    desc: "HVAC company in canada",
    year: "2021",
    img: case4,
  }
];
